<template>
  <div style="white-space: nowrap">
    <b-overlay :show="isLoading || isModalLoading" class="d-flex" style="gap: 8px">
      <!-- Seitenleiste -->
      <div>
        <div class="card p-2" style="gap: 12px; align-items: center; width: 100%">
          <div
            v-for="(saison, i) in metadata"
            :key="'saison-' + saison.id"
            class="p-3 rounded"
            style="cursor: pointer; width: 100%"
            :class="activeSaisonGueltigAb === saison.gueltigAb ? 'bg-primary text-white' : 'bg-secondary'"
            @click="setActiveSaisonGueltigAb(saison.gueltigAb)"
          >
            <div class="d-flex">
              {{ `${i + 1}. Saison` }}
            </div>
            <div>{{ 'Ab ' + saison.formattedGueltigAb }}</div>
            <div class="d-flex">
              <span>{{ saison.reisetermineOfThisSeason?.length }} RT</span>
              <a
                :class="activeSaisonGueltigAb === saison.gueltigAb ? 'bg-primary text-white' : 'bg-secondary'"
                class="ml-auto px-1"
                :href="
                  '/#/reisetermine/planung?reisetermine=' +
                  saison.reisetermineOfThisSeason.map(item => item.reiseterminkuerzel).join(',')
                "
                target="_blank"
              >
                <b-icon icon="box-arrow-up-right"></b-icon>
              </a>
            </div>
          </div>
          <div class="w-100" style="display: grid; grid-template-columns: repeat(5, 1fr)">
            <b-button
              class="btn-xs btn py-1 px-2 justify-content-center align-items-center d-flex"
              v-b-tooltip.hover
              :title="'Die Reisestammdaten werden pro Reise und pro Saison angelegt und gepflegt. Bei Anlage eines neuen Reisetermins werden die Daten der jeweiligen Saison, in der der Termin liegt, automatisch übernommen.'"
            >
              <i class="fas fa-info m-0 p-0"></i>
            </b-button>
            <div style="grid-column-start: 3" class="bg-primary plus-sign" v-b-modal.modalSaisonHinzufuegen>
              +
            </div>
          </div>
        </div>
      </div>
      <!-- Show active season -->
      <div class="d-flex w-100" style="flex-direction: column; position: relative">
        <span
          class="d-flex ml-auto rounded mr-4"
          style="gap: 12px; z-index: 99; padding: 12px; position: fixed; right: 0; top: 130px; float: left"
        >
          <b-button size="sm" @click="createTaskWithData" v-b-modal.modalAufgabeEdit>
            Aufgabe erstellen
          </b-button>
          <b-button
            @click="saveChanges"
            :disabled="disableSichernButton"
            :variant="disableSichernButton ? '' : 'primary'"
          >
            Speichern
          </b-button>
          <b-button
            v-b-modal.modalSaisonLöschen
            variant="secondary"
            v-if="activeMetadata?.gueltigAb?.substring(0, 10) !== '0001-01-01'"
          >
            Saison löschen
          </b-button>
        </span>
        <div class="p-6 mb-2 card" style="min-width: 900px">
          <h4 class="w-25 mb-4">Reisedaten</h4>

          <div class="d-flex px-4 mb-2" style="gap: 16px">
            <b-form-group label="Hotelnächte" class="widthEditField">
              <b-form-input
                :class="{ changed: hotelnaechteChanged }"
                size="sm"
                v-model="editedSeason.hotelnaechte"
                :number="true"
                type="number"
                min="0"
                max="365"
                step="1"
                required
              ></b-form-input>
            </b-form-group>
            <b-form-group label="Kalk Pax" class="widthEditField">
              <b-form-input
                :class="{ changed: kalkPaxChanged }"
                size="sm"
                v-model="editedSeason.kalkPax"
                :number="true"
                type="number"
                min="0"
                max="365"
                step="1"
                required
              ></b-form-input>
            </b-form-group>
            <b-form-group label="Erwartete Pax" class="widthEditField">
              <b-form-input
                :class="{ changed: erwartetePaxChanged }"
                size="sm"
                v-model="editedSeason.erwartetePax"
                :number="true"
                type="number"
                min="0"
                max="365"
                step="1"
                required
              ></b-form-input>
            </b-form-group>
            <div class="widthEditField">
              <b-form-group label="Erw. Termindurchführungsquote">
                <b-input-group size="sm">
                  <b-form-input
                    :class="{ changed: erwarteteTermindurchfuehrungsquoteChanged }"
                    size="sm"
                    v-model="editedSeason.erwarteteTermindurchfuehrungsquote"
                    :number="true"
                    type="number"
                  ></b-form-input>
                  <template #append>
                    <b-input-group-text>%</b-input-group-text>
                  </template>
                </b-input-group>
              </b-form-group>
            </div>
            <div class="widthEditField">
              <b-form-group label="Vorverlängerung">
                <b-input-group size="sm">
                  <b-form-input
                    :class="{ changed: vorverlaengerungChanged }"
                    size="sm"
                    v-model.number="editedSeason.vorverlaengerung"
                    :number="true"
                    type="number"
                    min="0"
                    @input="validateVorverlaengerung"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </div>
          </div>
          <div class="d-flex px-4 mb-2" style="gap: 16px">
            <b-form-group class="widthEditField">
              <template #label>
                <div class="d-flex align-items-center">
                  Durchführbare Min Pax
                  <b-button
                    class="btn-xs btn py-1 px-2 ml-2 justify-content-center align-items-center d-flex"
                    v-b-tooltip.hover
                    :title="'Theoretische Durchführbarkeit'"
                  >
                    <i class="fas fa-info m-0 p-0"></i>
                  </b-button>
                </div>
              </template>

              <b-form-input
                size="sm"
                :class="{ changed: durchfuehrbareMinPaxChanged }"
                v-model="editedSeason.durchfuehrbareMinPax"
                :number="true"
                type="number"
                min="0"
                max="365"
                step="1"
                required
              ></b-form-input>
            </b-form-group>
            <b-form-group label="Min Pax" class="widthEditField">
              <b-form-input
                size="sm"
                :class="{ changed: minPaxChanged }"
                v-model="editedSeason.minPax"
                :number="true"
                type="number"
                min="0"
                max="365"
                step="1"
                required
              ></b-form-input>
            </b-form-group>
            <b-form-group label="Max Pax" class="widthEditField">
              <b-form-input
                size="sm"
                :class="{ changed: maxPaxChanged }"
                v-model="editedSeason.maxPax"
                :number="true"
                type="number"
                min="0"
                max="365"
                step="1"
                required
              ></b-form-input>
            </b-form-group>
            <div class="widthEditField">
              <b-form-group label="EK kalk. Vornacht DZ p.P.">
                <b-input-group size="sm">
                  <b-form-input
                    :class="{ changed: einkaufspreisVornachtDzProPersonChanged }"
                    size="sm"
                    v-model="editedSeason.einkaufspreisVornachtDzProPerson"
                    :number="true"
                    type="number"
                  ></b-form-input>
                  <template #append>
                    <b-input-group-text>€</b-input-group-text>
                  </template>
                </b-input-group>
              </b-form-group>
            </div>
            <div class="widthEditField">
              <b-form-group label="VK kalk. Vornacht DZ p.P.">
                <b-input-group size="sm">
                  <b-form-input
                    size="sm"
                    :disabled="true"
                    :number="true"
                    type="number"
                    :placeholder="`${
                      activeMetadata?.vkVornachtDzProPerson
                        ? String(activeMetadata.vkVornachtDzProPerson).replace('.', ',')
                        : '0'
                    }€ (${
                      activeMetadata?.vkVornachtDzProPerson
                        ? Math.round(activeMetadata.vkVornachtDzProPerson / 10) * 10
                        : '0'
                    }€)`"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </div>
          </div>
        </div>
        <div class="p-6 mb-2 card">
          <h6 class="mb-6">Releaseinformationen</h6>
          <div class="d-flex align-items-center" style="gap: 16px">
            <b-form-group v-if="editedSeason.releasefrist?.length === 0" label="Releasefrist">
              <div class="bg-primary plus-sign" @click="onAddReleasefrist">+</div>
            </b-form-group>
            <div v-else v-for="item in editedSeason.releasefrist" :key="'releasefrist-' + item.id">
              <b-form-group class="widthEditField">
                <template #label>
                  <div class="d-flex align-items-center" style="gap: 4px">
                    <div>{{ `${item.id}. Releasefrist` }}</div>
                    <div
                      class="bg-primary plus-sign"
                      v-if="editedSeason.releasefrist?.length === item.id"
                      @click="onRemoveReleasefrist"
                    >
                      -
                    </div>
                    <div
                      v-if="editedSeason.releasefrist?.length === item.id"
                      class="bg-primary plus-sign"
                      @click="onAddReleasefrist"
                    >
                      +
                    </div>
                  </div>
                </template>
                <b-form-input
                  :class="{
                    changed:
                      item.id > activeMetadata.releasefrist?.length ||
                      +activeMetadata.releasefrist?.[item.id - 1] !== item.value,
                  }"
                  size="sm"
                  v-model="item.value"
                  :number="true"
                  type="number"
                  min="0"
                  max="365"
                  step="1"
                  required
                ></b-form-input>
              </b-form-group>
            </div>

            <b-form-group label="Kritische Destination" class="widthEditField">
              <b-form-checkbox
                size="lg"
                v-model="editedSeason.isKritischeDestination"
                :class="{ changed: isKritischeDestinationChanged }"
              ></b-form-checkbox>
            </b-form-group>
          </div>
        </div>

        <div class="p-6 mb-2 card" style="">
          <h6 class="mb-6">Transfer</h6>
          <div class="d-flex" style="gap: 16px">
            <b-form-group label="Transferszenario Hin" class="widthEditField">
              <b-form-select
                v-model="editedSeason.transferszenarioHin"
                size="sm"
                :class="{ changed: transferszenarioHinChanged }"
              >
                <b-form-select-option
                  v-for="transferszenario in transferszenarioOptions"
                  :value="transferszenario.value"
                  :key="'transferszenario-' + transferszenario.text"
                >
                  {{ transferszenario.text }}
                </b-form-select-option>
              </b-form-select>
            </b-form-group>
            <b-form-group label="Transferszenario Rück" class="widthEditField">
              <b-form-select
                v-model="editedSeason.transferszenarioRueck"
                size="sm"
                :class="{ changed: transferszenarioRueckChanged }"
              >
                <b-form-select-option
                  v-for="transferszenario in transferszenarioOptions"
                  :value="transferszenario.value"
                  :key="'transferszenario-' + transferszenario.text"
                >
                  {{ transferszenario.text }}
                </b-form-select-option>
              </b-form-select>
            </b-form-group>
            <b-form-group label="Transferkosten Währung" class="widthEditField">
              <Multiselect
                :class="{ changed: transferkostenWaehrungChanged }"
                v-model="editedSeason.transferkostenWaehrung"
                :options="currenciesOptions"
                :placeholder="'---'"
                :selectLabel="''"
                :selectGroupLabel="''"
                :deselectLabel="''"
                :deselectGroupLabel="''"
              ></Multiselect>
            </b-form-group>
          </div>

          <div class="d-flex" style="gap: 16px">
            <b-form-group label="Kommentar TS Hin" class="widthEditField">
              <b-form-textarea
                v-model="editedSeason.kommentarTransferszenarioHin"
                :class="{ changed: kommentarTransferszenarioHinChanged }"
                max-rows="3"
                size="sm"
              />
            </b-form-group>

            <b-form-group label="Kommentar TS Rück" class="widthEditField">
              <b-form-textarea
                v-model="editedSeason.kommentarTransferszenarioRueck"
                :class="{ changed: kommentarTransferszenarioRueckChanged }"
                max-rows="3"
                size="sm"
              />
            </b-form-group>
          </div>

          <div class="d-flex" style="gap: 16px">
            <b-form-group label="Transferkosten Hin" class="widthEditField">
              <b-input-group size="sm">
                <b-form-input
                  size="sm"
                  v-model="editedSeason.transferkostenHin"
                  :number="true"
                  type="number"
                  :class="{ changed: transferkostenHinChanged }"
                ></b-form-input>
                <template #append>
                  <b-input-group-text>{{ transferkostenWaehrungSymbol }}</b-input-group-text>
                </template>
              </b-input-group>
            </b-form-group>
            <b-form-group label="VK Transferkosten Hin" class="widthEditField">
              <b-input-group size="sm">
                <b-form-input
                  size="sm"
                  :placeholder="`${
                    activeMetadata?.vkTransferkostenHin
                      ? String(activeMetadata?.vkTransferkostenHin)?.replace('.', ',')
                      : '0'
                  }${transferkostenWaehrungSymbol ?? '€'} (${
                    activeMetadata?.vkTransferkostenHin
                      ? Math.round(activeMetadata?.vkTransferkostenHin / 10) * 10
                      : '0'
                  }${transferkostenWaehrungSymbol ?? '€'})`"
                  :disabled="true"
                  :number="true"
                  type="number"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
            <b-form-group label="Transferkosten Rück" class="widthEditField">
              <b-input-group size="sm">
                <b-form-input
                  :class="{ changed: transferkostenRueckChanged }"
                  size="sm"
                  v-model="editedSeason.transferkostenRueck"
                  :number="true"
                  type="number"
                ></b-form-input>
                <template #append>
                  <b-input-group-text>{{ transferkostenWaehrungSymbol }}</b-input-group-text>
                </template>
              </b-input-group>
            </b-form-group>
            <b-form-group label="VK Transferkosten Rück" class="widthEditField">
              <b-input-group size="sm">
                <b-form-input
                  size="sm"
                  :placeholder="`${
                    activeMetadata?.vkTransferkostenRueck
                      ? String(activeMetadata?.vkTransferkostenRueck)?.replace('.', ',')
                      : '0'
                  }${transferkostenWaehrungSymbol ?? '€'} (${
                    activeMetadata?.vkTransferkostenRueck
                      ? Math.round(activeMetadata?.vkTransferkostenRueck / 10) * 10
                      : '0'
                  }${transferkostenWaehrungSymbol ?? '€'})`"
                  :disabled="true"
                  :number="true"
                  type="number"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
          </div>
          <div class="d-flex" style="gap: 16px">
            <b-form-group label="Transferdauer Hin" class="widthEditField">
              <b-input-group size="sm" class="p-1" :class="{ changed: transferdauerHinChanged }">
                <Multiselect
                  v-model="editedSeason.transferdauerHin"
                  :options="transferdauerOptions"
                  :placeholder="'---'"
                  :selectLabel="''"
                  :selectGroupLabel="''"
                  :deselectLabel="''"
                  :deselectGroupLabel="''"
                ></Multiselect>
              </b-input-group>
            </b-form-group>
            <b-form-group label="Transferdauer Rück" class="widthEditField">
              <b-input-group size="sm" class="p-1" :class="{ changed: transferdauerRueckChanged }">
                <Multiselect
                  v-model="editedSeason.transferdauerRueck"
                  :options="transferdauerOptions"
                  :placeholder="'---'"
                  :selectLabel="''"
                  :selectGroupLabel="''"
                  :deselectLabel="''"
                  :deselectGroupLabel="''"
                ></Multiselect>
              </b-input-group>
            </b-form-group>
          </div>
        </div>

        <div class="p-6 mb-2 card">
          <h6 class="mb-6">Flugdaten</h6>
          <div class="widthEditField">
            <b-form-group label="SKR Flugangebot">
              <b-input-group size="sm">
                <b-form-input
                  size="sm"
                  v-model="editedSeason.skrFlugangebot"
                  :number="true"
                  type="number"
                  :class="{ changed: skrFlugangebotChanged }"
                ></b-form-input>
                <template #append>
                  <b-input-group-text>€</b-input-group-text>
                </template>
              </b-input-group>
            </b-form-group>
          </div>

          <div class="p-6 mb-2 card">
            <h6 class="mb-6">Hauptstrecke</h6>
            <div class="d-flex" style="gap: 16px">
              <div class="widthEditField">
                <b-form-group label="Zielland Zielflughäfen">
                  <AirportsSucheMultiselect
                    :isChangedMetadata="ziellandZielflughafenChanged"
                    v-model="editedSeason.ziellandZielflughaefenIataCodes"
                    :isMultipleAllowed="true"
                    :openDirection="'top'"
                    :filterCountry="reise?.reisekuerzel.substring(0, 2)"
                    id="abflughafen"
                  ></AirportsSucheMultiselect>
                </b-form-group>
                <b-form-group label="Zielland Zielflughafen Priorität">
                  <b-form-select
                    :class="{ changed: ziellandZielflughafenPrioChanged }"
                    size="sm"
                    :options="editedSeason.ziellandZielflughaefenIataCodes"
                    v-model="editedSeason.ziellandZielflughafenPrio"
                  ></b-form-select>
                </b-form-group>
              </div>
              <div class="widthEditField">
                <b-form-group label="Zielland Abflughäfen">
                  <AirportsSucheMultiselect
                    :isChangedMetadata="ziellandAbflughafenChanged"
                    v-model="editedSeason.ziellandAbflughaefenIataCodes"
                    :isMultipleAllowed="true"
                    :openDirection="'top'"
                    :filterCountry="reise?.reisekuerzel.substring(0, 2)"
                    id="abflughafen"
                  ></AirportsSucheMultiselect>
                </b-form-group>
                <b-form-group label="Zielland Abflughafen Priorität">
                  <b-form-select
                    :class="{ changed: ziellandAbflughafenPrioChanged }"
                    size="sm"
                    :options="editedSeason.ziellandAbflughaefenIataCodes"
                    v-model="editedSeason.ziellandAbflughafenPrio"
                  ></b-form-select>
                </b-form-group>
              </div>
              <div class="widthEditField">
                <b-form-group>
                  <template #label>
                    <div class="d-flex align-items-center">
                      Overnight Outbound
                      <b-button
                        class="btn-xs btn py-1 px-2 ml-2 justify-content-center align-items-center d-flex"
                        v-b-tooltip.hover
                        :title="'Der Hinflug erfolgt eventuell über Nacht, Landung im Zielland dann am nächsten oder übernächsten Tag.'"
                      >
                        <i class="fas fa-info m-0 p-0"></i>
                      </b-button>
                    </div>
                  </template>

                  <div class="d-flex">
                    <b-form-select
                      size="sm"
                      v-model="editedSeason.overnightOutbound"
                      :class="{ changed: overnightOutboundChanged }"
                    >
                      <b-form-select-option :value="null">-</b-form-select-option>
                      <b-form-select-option :value="0">0</b-form-select-option>
                      <b-form-select-option :value="1">+1</b-form-select-option>
                      <b-form-select-option :value="2">+2</b-form-select-option>
                    </b-form-select>
                  </div>
                </b-form-group>
                <b-form-group label="Hotelstart vor Landung" class="widthEditField">
                  <b-form-checkbox
                    size="lg"
                    v-model="editedSeason.hotelstartVorLandung"
                    :class="{ changed: hotelstartVorLandungChanged }"
                  ></b-form-checkbox>
                </b-form-group>
              </div>
              <div class="widthEditField">
                <b-form-group>
                  <template #label>
                    <div class="d-flex align-items-center">
                      Overnight Inbound
                      <b-button
                        class="btn-xs btn py-1 px-2 ml-2 justify-content-center align-items-center d-flex"
                        v-b-tooltip.hover
                        :title="'Der Rückflug erfolgt eventuell über Nacht, Landung in Deutschland dann am nächsten oder übernächsten Tag.'"
                      >
                        <i class="fas fa-info m-0 p-0"></i>
                      </b-button>
                    </div>
                  </template>
                  <div class="d-flex widthEditField">
                    <b-form-select
                      size="sm"
                      v-model="editedSeason.overnightInbound"
                      :class="{ changed: overnightInboundChanged }"
                    >
                      <b-form-select-option :value="null">-</b-form-select-option>
                      <b-form-select-option :value="0">0</b-form-select-option>
                      <b-form-select-option :value="1">+1</b-form-select-option>
                      <b-form-select-option :value="2">+2</b-form-select-option>
                    </b-form-select>
                  </div>
                </b-form-group>
                <b-form-group label="Rückflug nach Hotelende" class="widthEditField">
                  <b-form-checkbox
                    size="lg"
                    v-model="editedSeason.rueckflugNachHotelende"
                    :class="{ changed: rueckflugNachHotelendeChanged }"
                  ></b-form-checkbox>
                </b-form-group>
              </div>
            </div>
          </div>

          <div class="p-6 mb-2 card d-flex" style="gap: 16px">
            <div>
              <h6 class="mb-6">Restriktionen Hinflug</h6>
              <div class="d-flex" style="gap: 16px">
                <b-form-group label="Früheste Ankunft" class="widthEditField">
                  <DatePicker
                    :lang="langConfig"
                    type="time"
                    v-model="editedSeason.fruehesteAnkunft"
                    :show-second="false"
                    :format="formatDatetimepicker"
                    value-type="format"
                  ></DatePicker>
                </b-form-group>
                <b-form-group label="Späteste Ankunft" class="widthEditField">
                  <DatePicker
                    :lang="langConfig"
                    type="time"
                    v-model="editedSeason.spaetesteAnkunft"
                    :show-second="false"
                    :format="formatDatetimepicker"
                    value-type="format"
                  ></DatePicker>
                </b-form-group>
                <b-form-group label="" class="widthEditField d-flex align-items-center">
                  <div
                    class="d-flex align-items-center p-2"
                    :class="{ changed: restriktionenHinRolloverChanged }"
                  >
                    <b-form-checkbox
                      size="lg"
                      v-model="editedSeason.restriktionenHinRollover"
                      :disabled="true"
                    />
                    <div>+1</div>
                  </div>
                </b-form-group>
              </div>
            </div>
            <div>
              <h6 class="mb-6">Restriktionen Rückflug</h6>
              <div class="d-flex" style="gap: 16px">
                <b-form-group label="Frühester Abflug" class="widthEditField">
                  <DatePicker
                    :lang="langConfig"
                    type="time"
                    v-model="editedSeason.fruehesterAbflug"
                    :show-second="false"
                    :format="formatDatetimepicker"
                    value-type="format"
                  >
                    <template #input></template>
                  </DatePicker>
                </b-form-group>
                <b-form-group label="Spätester Abflug" class="widthEditField">
                  <DatePicker
                    :lang="langConfig"
                    type="time"
                    v-model="editedSeason.spaetesterAbflug"
                    :show-second="false"
                    :format="formatDatetimepicker"
                    value-type="format"
                  ></DatePicker>
                </b-form-group>
                <b-form-group label="" class="widthEditField d-flex align-items-center">
                  <div
                    class="d-flex align-items-center p-2"
                    :class="{ changed: restriktionenRueckRolloverChanged }"
                  >
                    <b-form-checkbox
                      size="lg"
                      v-model="editedSeason.restriktionenRueckRollover"
                      :disabled="true"
                    />
                    <div>+1</div>
                  </div>
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
        <div class="p-6 mb-2 card">
          <h6 class="mb-8 d-flex" style="cursor: pointer; align-items: center">
            <div @click="collapseInlandsfluege = !collapseInlandsfluege">
              <span v-if="editedSeason.inlandsfluege?.length > 0" class="mr-2">
                <b-icon
                  v-if="!collapseInlandsfluege"
                  style="color: #3699ff"
                  icon="chevron-right"
                  aria-hidden="true"
                  :scale="1.2"
                ></b-icon>
                <b-icon
                  v-else
                  style="color: #3699ff"
                  :scale="1.2"
                  icon="chevron-down"
                  aria-hidden="true"
                ></b-icon>
              </span>
              Inlandsflüge
            </div>
            <div
              class="ml-3 plus-sign"
              @click="addLocalInlandsflug"
              :class="isNonSavedInlandsflug ? 'bg-primary' : 'bg-secondary'"
            >
              +
            </div>
          </h6>
          <b-collapse
            v-if="editedSeason.inlandsfluege?.length > 0"
            id="collapse-inlandsflug"
            v-model="collapseInlandsfluege"
          >
            <div
              v-for="(inlandsflug, i) in sortedInlandsfluege"
              :key="'inlandsflug-' + inlandsflug.id"
              :style="inlandsflug?.isOnlyLocal && { 'background-color': '#f7f7f7' }"
              class="mb-8 p-4 rounded"
            >
              <h6 class="mb-4">
                {{ i + 1 }}. Inlandsflug
                <span
                  v-if="!inlandsflug?.isOnlyLocal"
                  class="ml-2"
                  @click="deleteInlandsflug(inlandsflug.id)"
                  style="cursor: pointer"
                >
                  <i class="fas fa-trash" style="color: black"></i>
                </span>
              </h6>

              <div class="d-flex" style="gap: 16px">
                <b-form-group label="Tag" class="widthEditField">
                  <b-form-input
                    size="sm"
                    v-model="inlandsflug.tag"
                    :number="true"
                    type="number"
                    min="0"
                    max="21"
                    step="1"
                    required
                    :disabled="inlandsflug?.isOnlyLocal ? false : true"
                  ></b-form-input>
                </b-form-group>
                <b-form-group label="Abflughafen" class="widthEditField">
                  <AirportsSucheMultiselect
                    :disabled="inlandsflug?.isOnlyLocal ? false : true"
                    v-model="inlandsflug.abflughafenIataCode"
                    :isMultipleAllowed="true"
                    :openDirection="'top'"
                    :filterCountry="reise?.reisekuerzel.substring(0, 2)"
                  ></AirportsSucheMultiselect>
                </b-form-group>
                <b-form-group label="Zielflughafen" class="widthEditField">
                  <AirportsSucheMultiselect
                    v-model="inlandsflug.zielflughafenIataCode"
                    :isMultipleAllowed="true"
                    :openDirection="'top'"
                    :filterCountry="reise?.reisekuerzel.substring(0, 2)"
                  ></AirportsSucheMultiselect>
                </b-form-group>
                <b-form-group label="Einkauf" class="widthEditField">
                  <b-form-select size="sm" v-model="inlandsflug.einkauf">
                    <b-form-select-option :value="null" disabled>-</b-form-select-option>
                    <b-form-select-option :value="'Skr'">SKR</b-form-select-option>
                    <b-form-select-option :value="'Dmc'">DMC</b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </div>
              <div class="d-flex" style="gap: 16px">
                <b-form-group label="Frühester Abflug" class="widthEditField">
                  <DatePicker
                    :lang="langConfig"
                    type="time"
                    v-model="inlandsflug.fruehesterAbflug"
                    :format="formatDatetimepicker"
                    :show-second="false"
                    value-type="format"
                  ></DatePicker>
                </b-form-group>
                <b-form-group label="Späteste Ankunft" class="widthEditField">
                  <DatePicker
                    :lang="langConfig"
                    type="time"
                    v-model="inlandsflug.spaetesteAnkunft"
                    :format="formatDatetimepicker"
                    :show-second="false"
                    value-type="format"
                    :disabled="
                      inlandsflug.minimaleUmsteigezeit != '' && inlandsflug.minimaleUmsteigezeit != null
                    "
                  ></DatePicker>
                </b-form-group>
                <div style="display: flex; align-items: center; justify-content: center; font-size: 1.25rem">
                  oder
                </div>
                <b-form-group label="Minimale Umsteigezeit" class="widthEditField">
                  <b-form-input
                    size="sm"
                    v-model="inlandsflug.minimaleUmsteigezeit"
                    :number="true"
                    type="number"
                    min="0"
                    step="1"
                    required
                    :disabled="inlandsflug.spaetesteAnkunft != null"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div v-if="changedInlandsfluegeIds.includes(inlandsflug.id)">
                <b-button class="mr-2" @click="saveInlandsflug(inlandsflug)">Speichern</b-button>
                <b-button @click="resetInlandsflug(inlandsflug.id)">Zurücksetzen</b-button>
              </div>
              <div v-if="inlandsflug?.isOnlyLocal">
                <b-button
                  class="mr-2"
                  :disabled="!isAllInlandsflugFieldsFilled"
                  :variant="isAllInlandsflugFieldsFilled ? 'primary' : 'secondary'"
                  @click="saveLocalInlandsflug(inlandsflug)"
                >
                  Speichern
                </b-button>
                <b-button @click="removeLocalInlandsfluege">Abbrechen</b-button>
              </div>
            </div>
          </b-collapse>
        </div>
      </div>
    </b-overlay>

    <b-modal id="modalSaisonHinzufuegen" centered title="Saison hinzufügen" size="sm">
      <b-overlay :show="isModalLoading">
        <b-form-group label="Gültig ab">
          <DatePicker :lang="langConfig" type="date" format="DD.MM.YYYY" v-model="gueltigAb"></DatePicker>
        </b-form-group>
      </b-overlay>
      <template #modal-footer="{ hide }" class="pl-1">
        <b-button
          class="mr-4"
          @click="handleModalSubmit(hide)"
          :disabled="gueltigAb === null"
          variant="success"
        >
          Erstellen
        </b-button>
        <b-button @click="resetAndCloseModal(hide)">Abbrechen</b-button>
      </template>
    </b-modal>
    <b-modal id="modalSaisonLöschen" centered title="Saison löschen" size="sm">
      <b-overlay :show="isModalLoading">
        <p>Möchtest du die Saison {{ activeSaisonGueltigAbFormatted }} wirklich löschen?</p>
      </b-overlay>
      <template #modal-footer="{ hide }" class="p-0 m-0">
        <b-button class="mr-2" @click="hide">Abbrechen</b-button>
        <b-button @click="deleteSaison(hide)" variant="danger">Löschen</b-button>
      </template>
    </b-modal>
    <b-modal
      id="modalShowNotUpdatedFields"
      centered
      hide-footer
      title="Die folgenden Daten der Termine aus der Saison wurden nicht verändert"
      size="lg"
      @hide="notUpdatedFields = null"
    >
      <div class="d-flex" style="flex-direction: column" v-if="notUpdatedFields !== null">
        <ul v-for="(value, key) in notUpdatedFields" :key="key">
          <li style="font-size: 14px">{{ key }}: {{ value.join(', ') }}</li>
        </ul>
        <h6 class="mt-4">
          Sieh sie dir
          <a target="_blank" :href="getLinkForReiseterminkuerzel(Object.keys(notUpdatedFields))">hier</a>
          in der Terminplanung an.
        </h6>
      </div>
    </b-modal>
    <b-modal
      id="modalShowNotUpdatedFieldsInlandsfluege"
      centered
      hide-footer
      title="Die folgenden Inlandsflüge der Termine aus der Saison wurden nicht verändert"
      size="lg"
      @hide="reiseterminNotUpdatedInlandsfluege = null"
    >
      <div class="d-flex" style="flex-direction: column">
        <ul v-for="reisetermin in reiseterminNotUpdatedInlandsfluege" :key="reisetermin">
          <li style="font-size: 14px">{{ reisetermin }}</li>
        </ul>
        <h6 class="mt-4">
          Sieh sie dir
          <a target="_blank" :href="getLinkForReiseterminkuerzel(reiseterminNotUpdatedInlandsfluege)">hier</a>
          in der Terminplanung an.
        </h6>
      </div>
    </b-modal>
    <AufgabeErstellenModal :prefixedVerlinkungen="reiseterminVerlinkungen" />
  </div>
</template>

<script>
import {
  formatDateWithoutHours,
  langConfig,
  arraysEqual,
  transferdauerOptions,
  transferszenarioOptions,
} from '@/core/common/helpers/utils.js';
import { compareAsc, compareDesc, format, isAfter, isBefore, isSameDay } from 'date-fns';
import AirportsSucheMultiselect from '@/components/common/airports-suche-multiselect.vue';
import Multiselect from 'vue-multiselect';
import currenciesJson from '@/core/common/helpers/currencies.json';
import DatePicker from 'vue2-datepicker';
import apiService from '@/core/common/services/api.service';
import MultipleReiseterminBearbeitenModal from '@/components/produkte/reisetermine/multiple-reisetermin-bearbeiten-modal.vue';
import { GET_REISE_DETAIL } from '@/core/produkte/reisen/stores/reisen.module';
import {
  SET_FULL_METADATA_HISTORY,
  SET_ACTIVE_SAISON_GUELTIG_AB,
} from '@/core/produkte/reisen/stores/reisen-metadata.module.js';
import { mapState } from 'vuex';
import AufgabeErstellenModal from '@/components/aufgaben/aufgabe-erstellen-modal.vue';

export default {
  name: 'ReisenMetadataTable',
  props: {
    reise: { type: Object },
  },
  components: {
    AirportsSucheMultiselect,
    Multiselect,
    DatePicker,
    MultipleReiseterminBearbeitenModal,
    AufgabeErstellenModal,
  },
  data() {
    return {
      langConfig,
      transferszenarioOptions,
      transferdauerOptions,
      notUpdatedFields: null,
      reiseterminNotUpdatedInlandsfluege: null,
      isLoading: null,
      isModalLoading: false,
      gueltigAb: null,
      metadata: null,
      currenciesOptions: [],
      collapseInlandsfluege: true,
      lastSaveChangedFields: [],
      preexistingFormDataMultipleReisetermin: null,
      reiseterminVerlinkungen: [],
      editedSeason: {
        hotelnaechte: null,
        kalkPax: null,
        erwartetePax: null,
        durchfuehrbareMinPax: null,
        minPax: null,
        maxPax: null,
        isKritischeDestination: null,
        transferszenarioHin: null,
        transferszenarioRueck: null,
        kommentarTransferszenarioHin: null,
        kommentarTransferszenarioRueck: null,
        transferkostenHin: null,
        transferkostenRueck: null,
        transferkostenWaehrung: null,
        ziellandZielflughaefenIataCodes: null,
        ziellandZielflughafenPrio: null,
        ziellandAbflughaefenIataCodes: null,
        ziellandAbflughafenPrio: null,
        overnightInbound: null,
        overnightOutbound: null,
        fruehesterAbflug: null,
        spaetesterAbflug: null,
        restriktionenHinRollover: null,
        fruehesteAnkunft: null,
        spaetesteAnkunft: null,
        restriktionenRueckRollover: null,
        releasefrist: null,
        skrFlugangebot: null,
        inlandsfluege: null,
        hotelstartVorLandung: null,
        rueckflugNachHotelende: null,
        erwarteteTermindurchfuehrungsquote: null,
        einkaufspreisVornachtDzProPerson: null,
        vorverlaengerung: null,
        transferdauerHin: null,
        transferdauerRueck: null,
      },
      formatDatetimepicker: 'HH:mm',
    };
  },
  mounted() {
    this.prepareData();
  },
  watch: {
    activeSaisonGueltigAb() {
      this.setEditedData();
    },
    editedSeason: {
      handler(v) {
        if (v.fruehesterAbflug != null && v.spaetesterAbflug != null) {
          const compareFruehesterAbflug = new Date().setHours(
            parseInt(v.fruehesterAbflug.substring(0, 2)),
            parseInt(v.fruehesterAbflug.substring(3, 5))
          );
          const compareSpaetesterAbflug = new Date().setHours(
            parseInt(v.spaetesterAbflug.substring(0, 2)),
            parseInt(v.spaetesterAbflug.substring(3, 5))
          );

          if (compareFruehesterAbflug >= compareSpaetesterAbflug) {
            this.editedSeason.restriktionenRueckRollover = true;
          } else {
            this.editedSeason.restriktionenRueckRollover = false;
          }
        } else {
          this.editedSeason.restriktionenRueckRollover = null;
        }

        if (v.fruehesteAnkunft != null && v.spaetesteAnkunft != null) {
          const compareFruehesteAnkunft = new Date().setHours(
            parseInt(v.fruehesteAnkunft.substring(0, 2)),
            parseInt(v.fruehesteAnkunft.substring(3, 5))
          );
          const compareSpaetesteAnkunft = new Date().setHours(
            parseInt(v.spaetesteAnkunft.substring(0, 2)),
            parseInt(v.spaetesteAnkunft.substring(3, 5))
          );

          if (compareFruehesteAnkunft >= compareSpaetesteAnkunft) {
            this.editedSeason.restriktionenHinRollover = true;
          } else {
            this.editedSeason.restriktionenHinRollover = false;
          }
        } else {
          this.editedSeason.restriktionenHinRollover = null;
        }
      },
      deep: true,
    },
    reise() {
      this.prepareData();
    },
  },
  computed: {
    ...mapState({
      activeSaisonGueltigAb: state => state.reisenMetadata.activeSaisonGueltigAb,
    }),
    activeSaisonGueltigAbFormatted() {
      return formatDateWithoutHours(this.activeSaisonGueltigAb);
    },
    fruehesteAnkunft() {
      return this.editedSeason.fruehesteAnkunft;
    },
    spaetesteAnkunft() {
      return this.editedSeason.spaetesteAnkunft;
    },
    fruehesterAbflug() {
      return this.editedSeason.fruehesterAbflug;
    },
    spaetesterAbflug() {
      return this.editedSeason.spaetesterAbflug;
    },
    sortedInlandsfluege() {
      return this.editedSeason.inlandsfluege.slice().sort((a, b) => a.tag - b.tag);
    },
    changedInlandsfluegeIds() {
      return this.editedSeason?.inlandsfluege
        ?.filter(inlandsflug => {
          const originalInlandsflug = this.metadata
            .find(item => item.gueltigAb === this.activeSaisonGueltigAb)
            .inlandsfluege.find(inlandsflugOriginal => inlandsflugOriginal.id === inlandsflug.id);
          if (!originalInlandsflug || inlandsflug?.isOnlyLocal) return false;
          return (
            originalInlandsflug.tag !== inlandsflug.tag ||
            originalInlandsflug.zielflughafenIataCode !== inlandsflug.zielflughafenIataCode ||
            originalInlandsflug.abflughafenIataCode !== inlandsflug.abflughafenIataCode ||
            originalInlandsflug.einkauf !== inlandsflug.einkauf ||
            originalInlandsflug.fruehesterAbflug?.substring(0, 5) !== inlandsflug.fruehesterAbflug ||
            originalInlandsflug.spaetesteAnkunft?.substring(0, 5) !== inlandsflug.spaetesteAnkunft
          );
        })
        .map(item => item.id);
    },
    isNonSavedInlandsflug() {
      return !this.editedSeason?.inlandsfluege?.some(inlandsflug => inlandsflug?.isOnlyLocal == true);
    },
    isAllInlandsflugFieldsFilled() {
      const localInlandsflug = this.editedSeason.inlandsfluege.find(
        inlandsflug => inlandsflug?.isOnlyLocal == true
      );
      if (localInlandsflug) {
        return (
          localInlandsflug.abflughafenIataCode?.length === 1 &&
          localInlandsflug.zielflughafenIataCode?.length === 1 &&
          localInlandsflug.tag !== null &&
          localInlandsflug.einkauf !== null &&
          ((localInlandsflug.fruehesterAbflug != null && localInlandsflug.spaetesteAnkunft != null) ||
            (localInlandsflug.fruehesterAbflug != null && localInlandsflug.minimaleUmsteigezeit !== null))
        );
      } else return false;
    },
    activeMetadata() {
      if (this.metadata) {
        return this.metadata.find(item => item.gueltigAb === this.activeSaisonGueltigAb);
      }
      return null;
    },
    activeMetadataIndex() {
      if (this.metadata) {
        return this.metadata.findIndex(item => item.gueltigAb === this.activeSaisonGueltigAb);
      }
      return null;
    },
    reisetermineInFutureAndSeason() {
      if (!isNaN(this.activeMetadataIndex) && this.activeMetadataIndex > -1) {
        const nextSaisonId = this.activeMetadataIndex === 0 ? null : this.activeMetadataIndex - 1;
        const nextGueltigAb = nextSaisonId === null ? null : this.metadata[nextSaisonId]?.gueltigAb;
        let reisetermine = this.reise.reisetermine.filter(
          rt =>
            isAfter(new Date(rt.abreisedatum), new Date()) &&
            isAfter(new Date(rt.abreisedatum), new Date(this.activeMetadata?.gueltigAb))
        );
        if (nextGueltigAb) {
          reisetermine = reisetermine.filter(rt =>
            isBefore(new Date(rt.abreisedatum), new Date(nextGueltigAb))
          );
        }
        return reisetermine;
      } else return [];
    },
    transferkostenWaehrungSymbol() {
      const waehrungSymbol = currenciesJson[this.activeMetadata?.transferkostenWaehrung]?.symbol;
      return waehrungSymbol;
    },
    oldAbflughafenString() {
      if (this.activeMetadata?.ziellandAbflughaefen) {
        return this.activeMetadata?.ziellandAbflughaefen
          .slice()
          .map(fh => fh.flughafenIataCode)
          .sort((a, b) => a.localeCompare(b))
          .join('');
      } else return '';
    },
    oldZielflughafenString() {
      if (this.activeMetadata?.ziellandZielflughaefen) {
        return this.activeMetadata?.ziellandZielflughaefen
          .slice()
          .map(fh => fh.flughafenIataCode)
          .sort((a, b) => a.localeCompare(b))
          .join('');
      } else return '';
    },
    newZielflughafenString() {
      if (this.editedSeason.ziellandZielflughaefenIataCodes) {
        return this.editedSeason.ziellandZielflughaefenIataCodes
          .slice()
          .sort((a, b) => a.localeCompare(b))
          .join('');
      } else return '';
    },
    newAbflughafenString() {
      if (this.editedSeason.ziellandAbflughaefenIataCodes) {
        return this.editedSeason.ziellandAbflughaefenIataCodes
          .slice()
          .sort((a, b) => a.localeCompare(b))
          .join('');
      } else return '';
    },
    changedFields() {
      return [
        this.ziellandZielflughafenPrioChanged ? 'ziellandZielflughafenPrio' : null,
        this.ziellandAbflughafenPrioChanged ? 'ziellandAbflughafenPrio' : null,
        this.ziellandZielflughafenChanged ? 'ziellandZielflughaefenIataCodes' : null,
        this.ziellandAbflughafenChanged ? 'ziellandAbflughaefenIataCodes' : null,
        this.hotelnaechteChanged ? 'hotelnaechte' : null,
        this.kalkPaxChanged ? 'kalkPax' : null,
        this.minPaxChanged ? 'minPax' : null,
        this.maxPaxChanged ? 'maxPax' : null,
        this.durchfuehrbareMinPaxChanged ? 'durchfuehrbareMinPax' : null,
        this.erwartetePaxChanged ? 'erwartetePax' : null,
        this.isKritischeDestinationChanged ? 'isKritischeDestination' : null,
        this.transferszenarioHinChanged ? 'transferszenarioHin' : null,
        this.transferszenarioRueckChanged ? 'transferszenarioRueck' : null,
        this.kommentarTransferszenarioHinChanged ? 'kommentarTransferszenarioHin' : null,
        this.kommentarTransferszenarioRueckChanged ? 'kommentarTransferszenarioRueck' : null,
        this.transferkostenHinChanged ? 'transferkostenHin' : null,
        this.transferkostenRueckChanged ? 'transferkostenRueck' : null,
        this.transferkostenWaehrungChanged ? 'transferkostenWaehrung' : null,
        this.skrFlugangebotChanged ? 'skrFlugangebot' : null,
        this.overnightInboundChanged ? 'overnightInbound' : null,
        this.overnightOutboundChanged ? 'overnightOutbound' : null,
        this.hotelstartVorLandungChanged ? 'hotelstartVorLandung' : null,
        this.rueckflugNachHotelendeChanged ? 'rueckflugNachHotelende' : null,
        this.fruehesteAnkunftChanged ? 'fruehesteAnkunft' : null,
        this.spaetesteAnkunftChanged ? 'spaetesteAnkunft' : null,
        this.restriktionenHinRolloverChanged ? 'restriktionenHinRollover' : null,
        this.fruehesterAbflugChanged ? 'fruehesterAbflug' : null,
        this.spaetesterAbflugChanged ? 'spaetesterAbflug' : null,
        this.restriktionenRueckRolloverChanged ? 'restriktionenRueckRollover' : null,
        this.erwarteteTermindurchfuehrungsquoteChanged ? 'erwarteteTermindurchfuehrungsquote' : null,
        this.einkaufspreisVornachtDzProPersonChanged ? 'einkaufspreisVornachtDzProPerson' : null,
        this.releasefristChanged ? 'releasefrist' : null,
        this.vorverlaengerungChanged ? 'vorverlaengerung' : null,
        this.transferdauerHinChanged ? 'transferdauerHin' : null,
        this.transferdauerRueckChanged ? 'transferdauerRueck' : null,
      ].filter(Boolean);
    },
    ziellandZielflughafenPrioChanged() {
      return (
        this.editedSeason.ziellandZielflughafenPrio !==
        this.activeMetadata?.ziellandZielflughaefen.find(fh => fh.isPrio)?.flughafenIataCode
      );
    },
    ziellandAbflughafenPrioChanged() {
      return (
        this.editedSeason.ziellandAbflughafenPrio !==
        this.activeMetadata?.ziellandAbflughaefen.find(fh => fh.isPrio)?.flughafenIataCode
      );
    },
    ziellandZielflughafenChanged() {
      return this.newZielflughafenString !== this.oldZielflughafenString;
    },
    ziellandAbflughafenChanged() {
      return this.newAbflughafenString !== this.oldAbflughafenString;
    },
    hotelnaechteChanged() {
      return +this.editedSeason.hotelnaechte !== +this.activeMetadata?.hotelnaechte;
    },
    kalkPaxChanged() {
      return +this.editedSeason.kalkPax !== +this.activeMetadata?.kalkPax;
    },
    minPaxChanged() {
      return +this.editedSeason.minPax !== +this.activeMetadata?.minPax;
    },
    maxPaxChanged() {
      return +this.editedSeason.maxPax !== +this.activeMetadata?.maxPax;
    },
    durchfuehrbareMinPaxChanged() {
      return +this.editedSeason.durchfuehrbareMinPax !== +this.activeMetadata?.durchfuehrbareMinPax;
    },
    erwartetePaxChanged() {
      return +this.editedSeason.erwartetePax !== +this.activeMetadata?.erwartetePax;
    },
    vorverlaengerungChanged() {
      return this.editedSeason?.vorverlaengerung !== this.activeMetadata?.vorverlaengerung;
    },
    isKritischeDestinationChanged() {
      return (
        Boolean(this.editedSeason.isKritischeDestination) !==
        Boolean(this.activeMetadata?.isKritischeDestination)
      );
    },
    hotelstartVorLandungChanged() {
      return (
        Boolean(this.editedSeason.hotelstartVorLandung) !== Boolean(this.activeMetadata?.hotelstartVorLandung)
      );
    },
    rueckflugNachHotelendeChanged() {
      return (
        Boolean(this.editedSeason.rueckflugNachHotelende) !==
        Boolean(this.activeMetadata?.rueckflugNachHotelende)
      );
    },
    restriktionenHinRolloverChanged() {
      return (
        Boolean(this.editedSeason.restriktionenHinRollover) !==
        Boolean(this.activeMetadata?.restriktionenHinRollover)
      );
    },
    restriktionenRueckRolloverChanged() {
      return (
        Boolean(this.editedSeason.restriktionenRueckRollover) !==
        Boolean(this.activeMetadata?.restriktionenRueckRollover)
      );
    },
    transferszenarioHinChanged() {
      return this.editedSeason.transferszenarioHin !== this.activeMetadata?.transferszenarioHin;
    },
    transferszenarioRueckChanged() {
      return this.editedSeason.transferszenarioRueck !== this.activeMetadata?.transferszenarioRueck;
    },
    kommentarTransferszenarioHinChanged() {
      return (
        this.editedSeason.kommentarTransferszenarioHin !== this.activeMetadata?.kommentarTransferszenarioHin
      );
    },
    kommentarTransferszenarioRueckChanged() {
      return (
        this.editedSeason.kommentarTransferszenarioRueck !==
        this.activeMetadata?.kommentarTransferszenarioRueck
      );
    },
    transferdauerHinChanged() {
      return this.editedSeason.transferdauerHin !== this.activeMetadata?.transferdauerHin;
    },
    transferdauerRueckChanged() {
      return this.editedSeason.transferdauerRueck !== this.activeMetadata?.transferdauerRueck;
    },
    transferkostenHinChanged() {
      return +this.editedSeason.transferkostenHin !== +this.activeMetadata?.transferkostenHin;
    },
    transferkostenRueckChanged() {
      return +this.editedSeason.transferkostenRueck !== +this.activeMetadata?.transferkostenRueck;
    },

    releasefristChanged() {
      return !this.arraysEqual(
        this.editedSeason.releasefrist.map(item => item.value),
        this.activeMetadata?.releasefrist
      );
    },
    transferkostenWaehrungChanged() {
      return this.editedSeason.transferkostenWaehrung !== this.activeMetadata?.transferkostenWaehrung;
    },
    skrFlugangebotChanged() {
      return this.editedSeason.skrFlugangebot !== this.activeMetadata?.skrFlugangebot;
    },
    overnightInboundChanged() {
      return this.editedSeason.overnightInbound !== this.activeMetadata?.overnightInbound;
    },
    overnightOutboundChanged() {
      return this.editedSeason.overnightOutbound !== this.activeMetadata?.overnightOutbound;
    },
    fruehesteAnkunftChanged() {
      return (
        this.editedSeason.fruehesteAnkunft?.substring(0, 5) !==
        this.activeMetadata?.fruehesteAnkunft?.substring(0, 5)
      );
    },
    spaetesteAnkunftChanged() {
      return (
        this.editedSeason.spaetesteAnkunft?.substring(0, 5) !==
        this.activeMetadata?.spaetesteAnkunft?.substring(0, 5)
      );
    },
    fruehesterAbflugChanged() {
      return (
        this.editedSeason.fruehesterAbflug?.substring(0, 5) !==
        this.activeMetadata?.fruehesterAbflug?.substring(0, 5)
      );
    },
    spaetesterAbflugChanged() {
      return (
        this.editedSeason.spaetesterAbflug?.substring(0, 5) !==
        this.activeMetadata?.spaetesterAbflug?.substring(0, 5)
      );
    },
    erwarteteTermindurchfuehrungsquoteChanged() {
      return (
        this.editedSeason.erwarteteTermindurchfuehrungsquote !==
        this.activeMetadata?.erwarteteTermindurchfuehrungsquote
      );
    },
    einkaufspreisVornachtDzProPersonChanged() {
      return (
        +this.editedSeason.einkaufspreisVornachtDzProPerson !==
        +this.activeMetadata?.einkaufspreisVornachtDzProPerson
      );
    },
    disableSichernButton() {
      return (
        !this.ziellandZielflughafenPrioChanged &&
        !this.ziellandAbflughafenPrioChanged &&
        !this.ziellandZielflughafenChanged &&
        !this.ziellandAbflughafenChanged &&
        !this.hotelnaechteChanged &&
        !this.kalkPaxChanged &&
        !this.minPaxChanged &&
        !this.maxPaxChanged &&
        !this.durchfuehrbareMinPaxChanged &&
        !this.erwartetePaxChanged &&
        !this.isKritischeDestinationChanged &&
        !this.transferszenarioHinChanged &&
        !this.transferszenarioRueckChanged &&
        !this.kommentarTransferszenarioHinChanged &&
        !this.kommentarTransferszenarioRueckChanged &&
        !this.transferkostenHinChanged &&
        !this.transferkostenRueckChanged &&
        !this.transferkostenWaehrungChanged &&
        !this.skrFlugangebotChanged &&
        !this.overnightInboundChanged &&
        !this.overnightOutboundChanged &&
        !this.hotelstartVorLandungChanged &&
        !this.rueckflugNachHotelendeChanged &&
        !this.fruehesteAnkunftChanged &&
        !this.spaetesteAnkunftChanged &&
        !this.restriktionenHinRolloverChanged &&
        !this.fruehesterAbflugChanged &&
        !this.spaetesterAbflugChanged &&
        !this.restriktionenRueckRolloverChanged &&
        !this.erwarteteTermindurchfuehrungsquoteChanged &&
        !this.einkaufspreisVornachtDzProPersonChanged &&
        !this.releasefristChanged &&
        !this.vorverlaengerungChanged &&
        !this.transferdauerHinChanged &&
        !this.transferdauerRueckChanged
      );
    },
  },
  methods: {
    createTaskWithData() {
      this.$bvModal.show('modalAufgabeEdit');
      this.reiseterminVerlinkungen = this.activeMetadata.reisetermineOfThisSeason.map((item, i) => ({
        id: i,
        typ: 'Reisetermin',
        artifactId: item.id,
        artifactText: item.reiseterminkuerzel,
      }));
    },
    validateVorverlaengerung() {
      if (this.editedSeason.vorverlaengerung < 0) {
        this.editedSeason.vorverlaengerung = 0;
      }
    },
    prepareData() {
      this.metadata = this.reise.fullMetadataHistory
        .map(item => {
          const reisetermineOfThisSeason = this.getReiseterminKuerzelInSeason(item.gueltigAb);
          return {
            ...item,
            formattedGueltigAb: formatDateWithoutHours(item.gueltigAb),
            reisetermineOfThisSeason: reisetermineOfThisSeason.map(item => ({
              id: item.id,
              reiseterminkuerzel: item.reiseterminkuerzel,
            })),
          };
        })
        .sort((a, b) => compareDesc(new Date(a.gueltigAb), new Date(b.gueltigAb)));

      if (this.$route.query.reiseterminAbreisedatum) {
        const { reiseterminAbreisedatum } = this.$route.query;
        const filteredReisemetadataGueltigAb = this.reise.fullMetadataHistory
          .slice()
          .sort((a, b) => compareDesc(new Date(a.gueltigAb), new Date(b.gueltigAb)))
          .map(item => item.gueltigAb)
          .filter(item => new Date(item) < new Date(reiseterminAbreisedatum));
        this.setActiveSaisonGueltigAb(filteredReisemetadataGueltigAb[0]);
      }
      if (this.activeSaisonGueltigAb === null) {
        this.setActiveSaisonGueltigAb(this.metadata[0].gueltigAb);
      }
      this.setEditedData();
      this.currenciesOptions = Object.keys(currenciesJson);
    },
    arraysEqual,
    onClickReisetermineOfSeason(e) {
      e.preventDefault();
    },
    getReiseterminKuerzelInSeason(lowerBound) {
      const sortedReisemetadataGueltigAb = this.reise.fullMetadataHistory
        .slice()
        .sort((a, b) => compareAsc(new Date(a.gueltigAb), new Date(b.gueltigAb)))
        .map(item => item.gueltigAb);
      const index = sortedReisemetadataGueltigAb.indexOf(lowerBound);

      if (index === sortedReisemetadataGueltigAb.length - 1) {
        return this.reise.reisetermine.filter(rt => new Date(rt.abreisedatum) >= new Date(lowerBound));
      }

      const upperBound = sortedReisemetadataGueltigAb[index + 1];
      return this.reise.reisetermine.filter(
        rt =>
          new Date(rt.abreisedatum) >= new Date(lowerBound) &&
          new Date(rt.abreisedatum) < new Date(upperBound)
      );
    },
    getLinkForReiseterminkuerzel(reiseterminkuerzels) {
      if (reiseterminkuerzels !== null) {
        return '/#/reisetermine/planung?reisetermine=' + reiseterminkuerzels;
      } else return '';
    },
    setActiveSaisonGueltigAb(i) {
      this.$store.commit(SET_ACTIVE_SAISON_GUELTIG_AB, i);
    },
    onReiseterminMetadataBearbeitet(result) {
      this.$store.dispatch(GET_REISE_DETAIL, { reisekuerzel: this.reisekuerzel }).then(reise => {
        const { fullMetadataHistory } = reise;
        this.$store.commit(SET_FULL_METADATA_HISTORY, {
          fullMetadataHistory: fullMetadataHistory,
        });
      });
    },
    async deleteInlandsflug(id) {
      this.isLoading = true;
      const reisekuerzel = this.reise.reisekuerzel;

      const overwriteReisetermine = await this.$bvModal.msgBoxConfirm(
        'Möchtest du das Löschen des Inlandsflugs für Reisetermine in dieser Saison übernehmen?',
        {
          centered: true,
          okTitle: 'Löschen',
          okVariant: 'danger',
          cancelTitle: 'Abbrechen',
        }
      );
      if (overwriteReisetermine) {
        apiService
          .delete(
            `Reise/${reisekuerzel}/metadata/${this.activeSaisonGueltigAb}/inlandsflug?inlandsflugId=${id}&overwriteReisetermine=${overwriteReisetermine}`
          )
          .then(() => {
            this.toast('Erfolgreich gelöscht');
            this.metadata.find(item => item.gueltigAb === this.activeSaisonGueltigAb).inlandsfluege =
              this.metadata
                .find(item => item.gueltigAb === this.activeSaisonGueltigAb)
                .inlandsfluege.filter(inlandsflug => inlandsflug.id !== id);
            this.editedSeason.inlandsfluege = this.editedSeason.inlandsfluege.filter(
              inlandsflug => inlandsflug.id !== id
            );
            this.isLoading = false;
          });
      }

      this.isLoading = false;
    },
    async saveLocalInlandsflug(inlandsflug) {
      const timeOnlyParams = ['spaetesteAnkunft', 'fruehesterAbflug'];

      const params = Object.keys(inlandsflug).reduce((acc, curr) => {
        if (curr === 'isOnlyLocal' || curr === 'id') return acc;
        else if (['zielflughafenIataCode', 'abflughafenIataCode'].includes(curr)) {
          acc[curr] = inlandsflug[curr].join();
        } else if (timeOnlyParams.includes(curr) && inlandsflug[curr]) {
          // add seconds so that .net accepts this value as TimeOnly
          acc[curr] = inlandsflug[curr] + ':00';
        } else {
          acc[curr] = inlandsflug[curr];
        }
        return acc;
      }, {});
      const overwriteReisetermine = await this.$bvModal.msgBoxConfirm(
        'Möchtest du den Inlandsflug für Reisetermine in dieser Saison übernehmen?',
        {
          centered: true,
          okTitle: 'Ja',
          cancelTitle: 'Nein',
        }
      );
      this.isLoading = true;
      return apiService
        .post(
          `/Reise/${this.reise.reisekuerzel}/metadata/${this.activeMetadata.gueltigAb}/inlandsflug?overwriteReisetermine=${overwriteReisetermine}`,
          params
        )
        .then(resp => {
          const newInlandsflug = resp.data.result.value;
          this.isLoading = false;
          // insert new inlandsflug
          this.removeLocalInlandsfluege();
          this.addNewInlandsflug(newInlandsflug);
        })
        .catch(err => {
          this.isLoading = false;
          this.removeLocalInlandsfluege();
        });
    },
    removeLocalInlandsfluege() {
      this.editedSeason.inlandsfluege = this.editedSeason.inlandsfluege.filter(
        inlandsflug => inlandsflug?.isOnlyLocal != true
      );
    },
    resetInlandsflug(id) {
      this.editedSeason.inlandsfluege = this.editedSeason.inlandsfluege.map(inlandsflug => {
        if (inlandsflug.id === id) {
          const oldInlandsflug = this.metadata
            .find(item => item.gueltigAb === this.activeSaisonGueltigAb)
            .inlandsfluege.find(inlandsflug => inlandsflug.id === id);
          return {
            ...oldInlandsflug,
            spaetesteAnkunft: oldInlandsflug.spaetesteAnkunft?.substring(0, 5),
            fruehesterAbflug: oldInlandsflug.fruehesterAbflug?.substring(0, 5),
          };
        } else return inlandsflug;
      });
    },
    addNewInlandsflug(inlandsflug) {
      this.editedSeason.inlandsfluege = [
        {
          ...inlandsflug,
          spaetesteAnkunft: inlandsflug.spaetesteAnkunft?.substring(0, 5),
          fruehesterAbflug: inlandsflug.fruehesterAbflug?.substring(0, 5),
        },
        ...this.editedSeason.inlandsfluege,
      ];
      const activeMetadata = this.metadata.find(item => item.gueltigAb === this.activeSaisonGueltigAb);
      if (activeMetadata) {
        activeMetadata.inlandsfluege = [
          {
            ...inlandsflug,
          },
          ...activeMetadata.inlandsfluege,
        ];
      }
    },
    addLocalInlandsflug() {
      if (this.isNonSavedInlandsflug) {
        this.editedSeason.inlandsfluege = [
          {
            isOnlyLocal: true,
            id: 999999,
            abflughafenIataCode: null,
            zielflughafenIataCode: null,
            tag: null,
            einkauf: null,
            fruehesterAbflug: null,
            spaetesteAnkunft: null,
            minimaleUmsteigezeit: null,
          },
          ...this.editedSeason.inlandsfluege,
        ];
      }
      this.collapseInlandsfluege = true;
    },
    handleModalSubmit(hide) {
      if (this.gueltigAb) {
        const metadataToCopy = this.metadata.find(item => item.gueltigAb === this.activeSaisonGueltigAb);
        const reisekuerzel = this.reise.reisekuerzel;
        const gueltigAbNewFormatted = format(new Date(this.gueltigAb), 'yyyy-MM-dd');
        const gueltigAbOldFormatted = format(new Date(metadataToCopy.gueltigAb), 'yyyy-MM-dd');
        this.isModalLoading = true;
        apiService
          .post(
            `Reise/${reisekuerzel}/metadata/${gueltigAbOldFormatted}/copyMetadata?gueltigAbNew=${gueltigAbNewFormatted}`
          )
          .then(response => {
            const result = response.data.result.value;
            this.$emit('reiseUpdated');
            this.toast('Erfolgreich erstellt.');

            this.setActiveSaisonGueltigAb(gueltigAbNewFormatted + 'T00:00:00Z');
            this.notUpdatedFields = result.item1;

            if (Object.keys(this.notUpdatedFields).length > 0) {
              this.$root.$emit('bv::show::modal', 'modalShowNotUpdatedFields');
            }
            this.gueltigAb = null;
            this.isModalLoading = false;
            hide();
          });
      }
    },
    setEditedData() {
      if (this.activeMetadata) {
        this.editedSeason = {
          hotelnaechte: this.activeMetadata.hotelnaechte,
          kalkPax: this.activeMetadata.kalkPax,
          erwartetePax: this.activeMetadata.erwartetePax,
          minPax: this.activeMetadata.minPax,
          maxPax: this.activeMetadata.maxPax,
          durchfuehrbareMinPax: this.activeMetadata.durchfuehrbareMinPax,
          isKritischeDestination: this.activeMetadata.isKritischeDestination,
          transferszenarioHin: this.activeMetadata.transferszenarioHin,
          transferszenarioRueck: this.activeMetadata.transferszenarioRueck,
          kommentarTransferszenarioHin: this.activeMetadata.kommentarTransferszenarioHin,
          kommentarTransferszenarioRueck: this.activeMetadata.kommentarTransferszenarioRueck,
          transferkostenHin: this.activeMetadata.transferkostenHin,
          transferkostenRueck: this.activeMetadata.transferkostenRueck,
          transferkostenWaehrung: this.activeMetadata.transferkostenWaehrung,
          ziellandAbflughaefenIataCodes: this.activeMetadata.ziellandAbflughaefen.map(
            fh => fh.flughafenIataCode
          ),
          ziellandZielflughaefenIataCodes: this.activeMetadata.ziellandZielflughaefen.map(
            fh => fh.flughafenIataCode
          ),
          ziellandAbflughafenPrio: this.activeMetadata.ziellandAbflughaefen.find(fh => fh.isPrio)
            ?.flughafenIataCode,
          ziellandZielflughafenPrio: this.activeMetadata.ziellandZielflughaefen.find(fh => fh.isPrio)
            ?.flughafenIataCode,
          overnightInbound: this.activeMetadata.overnightInbound,
          overnightOutbound: this.activeMetadata.overnightOutbound,
          // only take hours and seconds for consistency, because datepicker uses that format aswell
          fruehesteAnkunft: this.activeMetadata.fruehesteAnkunft?.substring(0, 5) ?? null,
          spaetesteAnkunft: this.activeMetadata.spaetesteAnkunft?.substring(0, 5) ?? null,
          restriktionenHinRollover: Boolean(this.activeMetadata.restriktionenHinRollover),
          fruehesterAbflug: this.activeMetadata.fruehesterAbflug?.substring(0, 5) ?? null,
          spaetesterAbflug: this.activeMetadata.spaetesterAbflug?.substring(0, 5) ?? null,
          restriktionenRueckRollover: Boolean(this.activeMetadata.restriktionenRueckRollover),
          releasefrist: this.activeMetadata.releasefrist.map((item, i) => ({ value: item, id: i + 1 })),
          skrFlugangebot: this.activeMetadata.skrFlugangebot,
          hotelstartVorLandung: this.activeMetadata.hotelstartVorLandung,
          rueckflugNachHotelende: this.activeMetadata.rueckflugNachHotelende,
          erwarteteTermindurchfuehrungsquote: this.activeMetadata.erwarteteTermindurchfuehrungsquote,
          einkaufspreisVornachtDzProPerson: this.activeMetadata.einkaufspreisVornachtDzProPerson,
          inlandsfluege: this.activeMetadata.inlandsfluege.map(inlandsflug => ({
            ...inlandsflug,
            spaetesteAnkunft: inlandsflug.spaetesteAnkunft?.substring(0, 5) ?? null,
            fruehesterAbflug: inlandsflug.fruehesterAbflug?.substring(0, 5) ?? null,
          })),
          vorverlaengerung: this.activeMetadata.vorverlaengerung,
          transferdauerHin: this.activeMetadata.transferdauerHin,
          transferdauerRueck: this.activeMetadata.transferdauerRueck,
        };
      }
    },
    saveChanges() {
      this.isLoading = true;
      const reisekuerzel = this.reise.reisekuerzel;
      this.lastSaveChangedFields = this.changedFields;
      const timeOnlyParams = ['fruehesteAnkunft', 'spaetesteAnkunft', 'fruehesterAbflug', 'spaetesterAbflug'];
      const filterOutKeys = ['inlandsfluege'];
      let params = Object.keys(this.editedSeason)
        .filter(item => !filterOutKeys.includes(item))
        .reduce((acc, curr) => {
          if (curr === 'releasefrist') {
            acc[curr] = this.editedSeason.releasefrist.map(item => item.value);
          } else if (curr === 'restriktionenHinRollover' || curr === 'restriktionenRueckRollover') {
            acc[curr] = +this.editedSeason[curr];
          } else if (timeOnlyParams.includes(curr) && this.editedSeason[curr]) {
            // add seconds so that .net accepts this value as TimeOnly
            acc[curr] = this.editedSeason[curr] + ':00';
          } else {
            acc[curr] = this.editedSeason[curr];
          }
          return acc;
        }, {});
      if (
        Object.keys(params).includes('ziellandZielflughaefenIataCodes') &&
        !Object.keys(params).includes('ziellandZielflughafenPrio')
      ) {
        params['ziellandZielflughafenPrio'] = this.editedSeason['ziellandZielflughafenPrio'];
      }
      if (
        Object.keys(params).includes('ziellandAbflughaefenIataCodes') &&
        !Object.keys(params).includes('ziellandAbflughafenPrio')
      ) {
        params['ziellandAbflughafenPrio'] = this.editedSeason['ziellandAbflughafenPrio'];
      }
      this.preexistingFormDataMultipleReisetermin = params;

      apiService
        .put(`Reise/${reisekuerzel}/metadataAndApplyToReisetermin/${this.activeSaisonGueltigAb}`, params)
        .then(response => {
          const result = response.data.result.value;
          const newMetadata = response.data.result.value.item2;
          this.toast('Erfolgreich bearbeitet.');
          const specialFields = [
            'ziellandAbflughaefenIataCodes',
            'ziellandZielflughaefenIataCodes',
            'ziellandAbflughafenPrio',
            'ziellandZielflughafenPrio',
          ];

          // only show not updated fields of changed fields
          const onlyChangedFields = Object.entries(result.item1).reduce((acc, curr) => {
            const reiseterminkuerzel = curr[0];
            const notUpdatedFields = curr[1];
            const lowerCaseLastSaveChangedFields = this.lastSaveChangedFields
              .map(field => field.toLowerCase())
              .map(field => {
                if (field === 'ziellandzielflughaefeniatacodes') {
                  return 'ziellandzielflughaefen';
                } else if (field === 'ziellandabflughaefeniatacodes') {
                  return 'ziellandabflughaefen';
                } else {
                  return field;
                }
              });

            const notChangedFields = notUpdatedFields.filter(key =>
              lowerCaseLastSaveChangedFields.includes(key.toLowerCase())
            );
            if (notChangedFields.length > 0) {
              acc[reiseterminkuerzel] = notChangedFields;
            }
            return acc;
          }, {});

          this.notUpdatedFields = onlyChangedFields;
          this.isLoading = false;

          if (
            this.reisetermineInFutureAndSeason?.length > 0 &&
            Object.keys(this.notUpdatedFields).length > 0
          ) {
            this.$root.$emit('bv::show::modal', 'modalShowNotUpdatedFields');
          }
          this.metadata = this.metadata.map(m => {
            if (m.gueltigAb.substring(0, 10) === this.activeSaisonGueltigAb?.substring(0, 10)) {
              for (const field of this.lastSaveChangedFields) {
                if (specialFields.includes(field)) {
                  m['ziellandZielflughaefen'] = newMetadata.ziellandZielflughaefenIataCodes.map(fh => ({
                    flughafenIataCode: fh,
                    isPrio: fh === newMetadata.ziellandZielflughafenPrio,
                  }));
                  m['ziellandAbflughaefen'] = newMetadata.ziellandAbflughaefenIataCodes.map(fh => ({
                    flughafenIataCode: fh,
                    isPrio: fh === newMetadata.ziellandAbflughafenPrio,
                  }));
                } else {
                  m[field] = newMetadata[field];
                }
              }
              m['vkTransferkostenHin'] = newMetadata['vkTransferkostenHin'];
              m['vkTransferkostenRueck'] = newMetadata['vkTransferkostenRueck'];
              m['vkVornachtDzProPerson'] = newMetadata['vkVornachtDzProPerson'];
              return m;
            } else return m;
          });
        })
        .catch(() => {
          this.setEditedData();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    async saveInlandsflug(inlandsflug) {
      this.isLoading = true;
      const reisekuerzel = this.reise.reisekuerzel;

      apiService
        .put(
          `Reise/${reisekuerzel}/metadata/${this.activeSaisonGueltigAb}/inlandsflug?inlandsflugId=${inlandsflug.id}&overwriteReisetermine=true`,
          {
            tag: inlandsflug.tag,
            einkauf: inlandsflug.einkauf,
            fruehesterAbflug: inlandsflug.fruehesterAbflug
              ? inlandsflug.fruehesterAbflug + ':00'
              : inlandsflug.fruehesterAbflug,
            spaetesteAnkunft: inlandsflug.spaetesteAnkunft
              ? inlandsflug.spaetesteAnkunft + ':00'
              : inlandsflug.spaetesteAnkunft,
            minimaleUmsteigezeit: inlandsflug.minimaleUmsteigezeit,
            zielflughafenIataCode:
              typeof inlandsflug.zielflughafenIataCode === 'string'
                ? inlandsflug.zielflughafenIataCode
                : inlandsflug.zielflughafenIataCode.join(),
            abflughafenIataCode:
              typeof inlandsflug.abflughafenIataCode === 'string'
                ? inlandsflug.abflughafenIataCode
                : inlandsflug.abflughafenIataCode.join(),
          }
        )
        .then(response => {
          this.toast('Erfolgreich bearbeitet.');
          this.reiseterminNotUpdatedInlandsfluege = response.data.result.value.item1;

          if (this.reiseterminNotUpdatedInlandsfluege?.length > 0) {
            this.$root.$emit('bv::show::modal', 'modalShowNotUpdatedFieldsInlandsfluege');
          }
          const result = response.data.result.value.item2;
          // update data
          const inlandsflugToUpdate = this.metadata
            .find(item => item.gueltigAb === this.activeSaisonGueltigAb)
            .inlandsfluege.find(inl => inl.id === inlandsflug.id);
          inlandsflugToUpdate.tag = result.tag;
          inlandsflugToUpdate.einkauf = result.einkauf;
          inlandsflugToUpdate.fruehesterAbflug = result.fruehesterAbflug?.substring(0, 5);
          inlandsflugToUpdate.spaetesteAnkunft = result.spaetesteAnkunft?.substring(0, 5);
          inlandsflugToUpdate.minimaleUmsteigezeit = result.minimaleUmsteigezeit;
          inlandsflugToUpdate.abflughafenIataCode = result.abflughafenIataCode;
          inlandsflugToUpdate.zielflughafenIataCode = result.zielflughafenIataCode;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    async deleteSaison(hide) {
      if (!isSameDay(new Date(this.activeMetadata.gueltigAb), new Date('0001-01-01'))) {
        const reisekuerzel = this.reise.reisekuerzel;
        const gueltigAb = this.activeMetadata?.gueltigAb;
        this.isModalLoading = true;
        apiService.delete(`Reise/${reisekuerzel}/metadata/${gueltigAb}`).then(response => {
          const result = response.data.result.value;
          this.$emit('reiseUpdated');
          this.toast('Erfolgreich gelöscht.');
          this.setActiveSaisonGueltigAb(null);
          this.notUpdatedFields = result;

          if (Object.keys(this.notUpdatedFields).length > 0) {
            this.$root.$emit('bv::show::modal', 'modalShowNotUpdatedFields');
          }

          this.isModalLoading = false;
          hide();
        });
      }
    },
    onAddReleasefrist() {
      const length = this.editedSeason.releasefrist.length;
      this.editedSeason.releasefrist = [...this.editedSeason.releasefrist, { value: 0, id: length + 1 }];
    },
    onRemoveReleasefrist() {
      const length = this.editedSeason.releasefrist.length;
      this.editedSeason.releasefrist.pop();
    },
    resetAndCloseModal(hide) {
      this.gueltigAb = null;
      hide();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/components/_variables.bootstrap.scss';
.plus-sign {
  height: 24px;
  width: 24px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: white;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.widthEditField {
  width: 225px;
}

:deep(.changed) {
  background-color: #3699ff50;
}
:deep(.modal-footer) {
  padding: 5px;
}
</style>
